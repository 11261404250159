import { useState, useCallback, useEffect } from "react";

let logoutTimer;

export const useAuth = () => {
  const [token, setToken] = useState(false);
  const [tokenExpirationDate, setTokenExpirationDate] = useState();
  const [userId, setUserId] = useState(false);
  const [email, setUserEmail] = useState(null);
  const [name, setUserName] = useState(null);
  const [role, setUserRole] = useState(null);

  const login = useCallback((userId, email, name, role, token, expDate) => {
    setToken(token);
    setUserId(userId);
    setUserEmail(email);
    setUserName(name);
    setUserRole(role);
    const tokenExpDate =
      expDate || new Date(new Date().getTime() + 1000 * 60 * 60 * 5); //5h validity of token from server
    setTokenExpirationDate(tokenExpDate);
    localStorage.setItem(
      "userData",
      JSON.stringify({
        userId: userId,
        token: token,
        expiration: tokenExpDate.toISOString(),
        email: email,
        name: name,
        role: role,
      })
    );
  }, []);

  const logout = useCallback(() => {
    setToken(null);
    setTokenExpirationDate(null);
    setUserId(null);
    setUserEmail(null);
    setUserName(null);
    setUserRole(null);
    localStorage.removeItem("userData");
  }, []);

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime =
        tokenExpirationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpirationDate]);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userData"));
    if (
      storedData &&
      storedData.token &&
      new Date(storedData.expiration) > new Date()
    ) {
      login(
        storedData.userId,
        storedData.email,
        storedData.name,
        storedData.role,
        storedData.token,
        new Date(storedData.expiration)
      );
    }
  }, [login]);

  return { login, logout, userId, token, email, name, role };
};
