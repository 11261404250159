const VALIDATOR_TYPE_REQUIRE = "REQUIRE";
const VALIDATOR_TYPE_MINLENGTH = "MINLENGTH";
const VALIDATOR_TYPE_MAXLENGTH = "MAXLENGTH";
const VALIDATOR_TYPE_MIN = "MIN";
const VALIDATOR_TYPE_MAX = "MAX";
const VALIDATOR_TYPE_EMAIL = "EMAIL";
const VALIDATOR_TYPE_FILE = "FILE";
const VALIDATOR_TYPE_REGEX = "REGEX";

export const VALID_REQUIRE = () => ({ type: VALIDATOR_TYPE_REQUIRE });
export const VALID_FILE = () => ({ type: VALIDATOR_TYPE_FILE });
export const VALID_MINLENGTH = (val) => ({
  type: VALIDATOR_TYPE_MINLENGTH,
  val: val,
});
export const VALID_MAXLENGTH = (val) => ({
  type: VALIDATOR_TYPE_MAXLENGTH,
  val: val,
});
export const VALID_MIN = (val) => ({ type: VALIDATOR_TYPE_MIN, val: val });
export const VALID_MAX = (val) => ({ type: VALIDATOR_TYPE_MAX, val: val });
export const VALID_EMAIL = () => ({ type: VALIDATOR_TYPE_EMAIL });
export const VALID_REGEX = (val) => ({
  type: VALIDATOR_TYPE_REGEX,
  val: val,
});

export const validate = (value, validators) => {
  let isValid = true;
  if (validators) {
    for (const validator of validators) {
      if (validator.type === VALIDATOR_TYPE_REQUIRE) {
        isValid = isValid && value.trim().length > 0;
      }
      if (validator.type === VALIDATOR_TYPE_MINLENGTH) {
        isValid = isValid && value.trim().length >= validator.val;
      }
      if (validator.type === VALIDATOR_TYPE_MAXLENGTH) {
        isValid = isValid && value.trim().length <= validator.val;
      }
      if (validator.type === VALIDATOR_TYPE_MIN) {
        isValid = isValid && +value >= validator.val;
      }
      if (validator.type === VALIDATOR_TYPE_MAX) {
        isValid = isValid && +value <= validator.val;
      }
      if (validator.type === VALIDATOR_TYPE_EMAIL) {
        isValid = isValid && /^\S+@\S+\.\S+$/.test(value);
      }
      if (validator.type === VALIDATOR_TYPE_REGEX) {
        isValid = isValid && validator.val.test(value);
      }
    }
  }
  return isValid;
};
