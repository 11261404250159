import React, { useState, useContext } from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";

import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { AuthContext } from "../../context/auth-context";

import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";

import AccountCircle from "@mui/icons-material/AccountCircle";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";

const NavBar = (props) => {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSettingsClick = () => {
    navigate(`/users/${auth.userId}`);
    setAnchorElUser(null);
  };

  const handleUsersClick = () => {
    navigate(`/users/`);
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    handleCloseUserMenu();
    auth.logout();
  };

  return (
    <AppBar position="static">
      <Container maxWidth="false">
        <Toolbar disableGutters>
          <Box sx={{ display: { xs: "none", md: "flex" }, mr: 2 }}>
            <img src="/assets/logo.png" alt="Elektro Ringhof" height="40px" />
          </Box>

          <Typography
            variant="h6"
            noWrap
            component="a"
            sx={{
              mt: "8px",
              display: "flex",
              flexGrow: 1,
              fontWeight: 700,
              color: "inherit",
            }}
          >
            {props.title}
          </Typography>

          <Box sx={{ flexGrow: 0 }}>
            {!!auth.token && (
              <React.Fragment>
                <Tooltip title={auth.name}>
                  <IconButton
                    onClick={handleOpenUserMenu}
                    sx={{ p: 2, color: "inherit" }}
                  >
                    <AccountCircle />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {auth.role === "admin" && (
                    <MenuItem key="users" onClick={handleUsersClick}>
                      <Typography textAlign="center">Benutzer</Typography>
                    </MenuItem>
                  )}
                  <MenuItem key="settings" onClick={handleSettingsClick}>
                    <Typography textAlign="center">Einstellungen</Typography>
                  </MenuItem>
                  <MenuItem key="logout" onClick={handleLogout}>
                    <Typography textAlign="center">Logout</Typography>
                  </MenuItem>
                </Menu>
              </React.Fragment>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default NavBar;
