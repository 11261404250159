import React, { useReducer, useEffect } from "react";

import Stack from "@mui/material/Stack";
import { format, parseISO } from "date-fns";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Tooltip from "@mui/material/Tooltip";

import { validate } from "../../util/validators";

const inputReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators),
      };
    case "TOUCH": {
      return {
        ...state,
        isTouched: true,
      };
    }
    default:
      return state;
  }
};

const Control = (props) => {
  const [inputState, dispatch] = useReducer(inputReducer, {
    value: 
      props.type === "checkbox"
        ? props.initialValue
        : props.initialValue || ""
        ,
    isValid: props.initialIsValid || false,
    isTouched: false,
  });

  const { id, onInput } = props;
  const { value, isValid } = inputState;

  useEffect(() => {
    onInput(id, value, isValid);
  }, [id, value, isValid, onInput]);

  const handleChange = (event) => {
    dispatch({
      type: "CHANGE",
      val:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
      validators: props.validators,
    });
  };

  const handleTouch = () => {
    dispatch({
      type: "TOUCH",
    });
  };

  const getControl = () => {
    if (!props.editing) {
      return (
        <React.Fragment>
          <Stack direction="row" spacing={2}>
            <Typography
              color="primary.main"
              fontWeight="bold"
              sx={{ width: 200 }}
            >
              {props.type === "checkbox" && !props.label
                ? props.labelChecked + "/" + props.labelUnchecked
                : props.label}
              :
            </Typography>
            <Typography color="inherit">
              {props.type === "date"
                ? format(parseISO(inputState.value), "dd.MM.yyyy")
                : props.type === "password"
                ? "********"
                : props.type === "checkbox"
                ? inputState.value
                  ? props.labelChecked
                  : props.labelUnchecked
                : props.type === "select"
                  ? inputState.value === 999999999
                  ? "0"
                  // 0 wird von formState nicht unterstützt
                  : inputState.value
                : inputState.value}
            </Typography>
          </Stack>
        </React.Fragment>
      );
    }
    switch (props.type) {
      case "input":
        return (
          <Tooltip title={props.tooltip} placement="bottom-start">
            <TextField
              id={props.id}
              label={props.label}
              variant="outlined"
              error={!inputState.isValid && inputState.isTouched}
              onChange={handleChange}
              onBlur={handleTouch}
              value={inputState.value}
              placeholder={props.placeholder}
              required={props.required}
              disabled={props.disabled}
            />
          </Tooltip>
        );
      case "email":
        return (
          <Tooltip title={props.tooltip} placement="bottom-start">
            <TextField
              id={props.id}
              type="email"
              label={props.label}
              variant="outlined"
              error={!inputState.isValid && inputState.isTouched}
              onChange={handleChange}
              onBlur={handleTouch}
              value={inputState.value}
              placeholder={props.placeholder}
              required={props.required}
              disabled={props.disabled}
            />
          </Tooltip>
        );
      case "date":
        return (
          <Tooltip title={props.tooltip} placement="bottom-start">
            <TextField
              id={props.id}
              type="date"
              label={props.label}
              variant="outlined"
              format="dd/MM/yyyy"
              error={!inputState.isValid && inputState.isTouched}
              onChange={handleChange}
              onBlur={handleTouch}
              value={inputState.value}
              placeholder="tte "
              required={props.required}
              disabled={props.disabled}
              InputLabelProps={{ shrink: true }}
            />
          </Tooltip>
        );
      case "password":
        return (
          <Tooltip title={props.tooltip} placement="bottom-start">
            <TextField
              id={props.id}
              type="password"
              label={props.label}
              variant="outlined"
              error={!inputState.isValid && inputState.isTouched}
              onChange={handleChange}
              onBlur={handleTouch}
              value={inputState.value}
              placeholder={props.placeholder}
              disabled={props.disabled}
              required={props.required}
            />
          </Tooltip>
        );
      case "select":
        return (
          <React.Fragment>
            <InputLabel
              id={`${props.id}-label`}
              disabled={props.disabled}
              error={!inputState.isValid && inputState.isTouched}
            >
              {props.label} {props.required ? " *" : ""}
            </InputLabel>
            <Tooltip title={props.tooltip} placement="bottom-start">
              <Select
                labelId={`${props.id}-label`}
                id={props.id}
                label={props.label}
                displayEmpty
                disabled={props.disabled}
                required={props.required}
                value={inputState.value}
                onChange={handleChange}
                onBlur={handleTouch}
                error={!inputState.isValid && inputState.isTouched}
              >
                {props.options.map((option) => (
                  <MenuItem
                    value={option.value}
                    key={option.value}
                    disabled={option.disabled || false}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </Tooltip>
          </React.Fragment>
        );
      case "checkbox":
        return (
          <Tooltip
            title={
              inputState.value ? props.tooltipChecked : props.tooltipUnchecked
            }
            placement="bottom-start"
          >
            <FormControlLabel
              disabled={props.disabled}
              label={inputState.value ? props.labelChecked : props.labelUnchecked}
              control={
                <Checkbox
                  checked={inputState.value}
                  icon={props.iconUnchecked}
                  checkedIcon={props.iconChecked}
                  onChange={handleChange}
                  onBlur={handleTouch}
                />
              }
            />
          </Tooltip>
        );
      default:
        return null;
    }
  };

  const control = getControl();

  return (
    <FormControl sx={{ mt: 2 }} fullWidth>
      {control}
      <FormHelperText error={!inputState.isValid && inputState.isTouched}>
        {!inputState.isValid && inputState.isTouched
          ? props.errorText
          : props.helperText}
      </FormHelperText>
    </FormControl>
  );
};

export default Control;
