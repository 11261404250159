import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import ProtectedRoute from "./shared/components/Navigation/ProtectedRoute";

import Auth from "./user/pages/Auth";
import { AuthContext } from "./shared/context/auth-context";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import NavBar from "./shared/components/Navigation/NavBar";
import { useAuth } from "./shared/hooks/auth-hook";
import LoadingSpinner from "./shared/components/LoadingSpinner";

const Assets = React.lazy(() => import("./asset/pages/Assets"));
const AssetGeneral = React.lazy(() => import("./asset/pages/AssetGeneral"));
const AssetNew = React.lazy(() => import("./asset/pages/AssetNew"));
const Audits = React.lazy(() => import("./asset/audit/pages/Audits"));
const Audit = React.lazy(() => import("./asset/audit/pages/Audit"));
const AuditNew = React.lazy(() => import("./asset/audit/pages/AuditNew"));
const UserSettings = React.lazy(() => import("./user/pages/UserSettings"));
const Users = React.lazy(() => import("./user/pages/Users"));
const UserNew = React.lazy(() => import("./user/pages/UserNew"));

const App = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#104487",
      },
    },
  });

  const { login, logout, userId, token, email, name, role } = useAuth();

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AuthContext.Provider
          value={{
            userId: userId,
            token: token,
            email: email,
            name: name,
            role: role,
            login: login,
            logout: logout,
          }}
        >
          <NavBar title="Anlagen-Manager" />
          <main>
            <Suspense fallback={<LoadingSpinner />}>
              <Routes>
                <Route element={<ProtectedRoute isAllowed={!!token} />}>
                  <Route path="/assets" element={<Assets />} />
                  <Route path="/assets/new" element={<AssetNew />} />
                  <Route path="/assets/:assetId" element={<AssetGeneral />} />
                  <Route path="/assets/:assetId/audits" element={<Audits />} />
                  <Route
                    path="/assets/:assetId/audits/new"
                    element={<AuditNew />}
                  />
                  <Route
                    path="/assets/:assetId/audits/:auditId"
                    element={<Audit />}
                  />
                  <Route
                    element={<ProtectedRoute isAllowed={role === "admin"} />}
                  >
                    <Route path="/users" element={<Users />} />
                    <Route path="/users/new" element={<UserNew />} />
                  </Route>
                  <Route path="/users/:userId" element={<UserSettings />} />
                </Route>
                <Route
                  path="*"
                  element={
                    !!token ? (
                      <Navigate to="/assets" replace />
                    ) : (
                      <Navigate to="/login" replace />
                    )
                  }
                />
                <Route
                  path="/login"
                  element={
                    <ProtectedRoute isAllowed={!token} redirectPath="/">
                      <Auth />
                    </ProtectedRoute>
                  }
                />
              </Routes>
            </Suspense>
          </main>
        </AuthContext.Provider>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
