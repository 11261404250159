import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ErrorIcon from "@mui/icons-material/Error";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ErrorDialog = (props) => {
  return (
    <Dialog
      open={!!props.error}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle id="alert-dialog-title" sx={{ p: 0, pt: 1 }}>
        <ErrorIcon
          slot="start"
          sx={{
            mr: "10px",
            mb: "-50px",
            color: "#f02e2e",
            fontSize: "80px",
            p: 2,
          }}
        ></ErrorIcon>
        {props.title || "Fehler"}
      </DialogTitle>
      <DialogContent sx={{ mt: "10px", pb: 0, pl: "90px" }}>
        <DialogContentText id="alert-dialog-description">
          {props.error}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose || ""}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorDialog;
