import React, { useContext } from "react";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Box from "@mui/material/Box";
import Control from "../../shared/components/FormElements/Control";
import ErrorDialog from "../../shared/components/ErrorDialog";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import { VALID_EMAIL } from "../../shared/util/validators";
import { useForm } from "../../shared/hooks/form-hook";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import "./Auth.css";

const Auth = (props) => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [formState, handleChange] = useForm(
    {
      email: {
        value: "",
        isValid: false,
      },
      password: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/login`,
        "POST",
        JSON.stringify({
          email: formState.inputs.email.value,
          password: formState.inputs.password.value,
        }),
        {
          "Content-Type": "application/json",
        }
      );
      auth.login(
        responseData.userId,
        responseData.email,
        responseData.name,
        responseData.role,
        responseData.token
      );
    } catch (err) {}
  };

  return (
    <React.Fragment>
      <LoadingSpinner open={isLoading} />
      <ErrorDialog
        title="Login fehlgeschlagen"
        error={error}
        onClose={clearError}
      />
      <Box
        component="form"
        onSubmit={handleLoginSubmit}
        noValidate
        autoComplete="off"
        sx={{
          position: "absolute",
          p: 4,
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          border: "1px solid black",
          borderRadius: "10px",
        }}
      >
        <h2>Login</h2>

        <Control
          type="email"
          id="email"
          label="E-Mail"
          required
          editing
          onInput={handleChange}
          initialValue={formState.inputs.email.value}
          initialIsValid={formState.inputs.email.isValid}
          validators={[VALID_EMAIL()]}
          errorText="Ungültige E-Mail-Adresse"
        />
        <Control
          type="password"
          id="password"
          label="Passwort"
          required
          editing
          onInput={handleChange}
          initialValue={formState.inputs.password.value}
          initialIsValid={formState.inputs.password.isValid}
        />

        <ButtonGroup variant="contained" sx={{ mt: 3 }}>
          <Button type="submit" disabled={!formState.isValid}>
            LOGIN
          </Button>
        </ButtonGroup>
      </Box>
    </React.Fragment>
  );
};

export default Auth;
